import { reactive } from "vue"
export const store = reactive({
    activeTabs: {},
    tabsArr: {}
})
export const setters = {
    activeTab: (name, val) => {
        store.activeTabs[name] = val
    }
}

export const getters = {
    activeTab: (name) => {
        return store.activeTabs[name]
    },
    tabsArr: (name) => {
        return store.tabsArr[name]
    }
}

export const actions = {
    pushTabs: (name,val) => {
        if(!store.tabsArr[name]) {
            store.tabsArr[name] = []
        }
        store.tabsArr[name].push(val)
    }
}