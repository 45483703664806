<template>
  <div class="tabs__navigation-item" :class="{'tabs__navigation-item--active' : isActive}" @click="setActive">
    <slot/>
  </div>
</template>

<script>
import { setters, getters, actions } from "./TabsStore"

export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    parentName: {
      type: String,
      default: ''
    },
  },
  computed: {
    isActive() {
      return !!getters.activeTab(this.parentName) ? getters.activeTab(this.parentName) == this.name : getters.tabsArr(this.parentName)[0] == this.name
    }
  },
  beforeCreate() {
    actions.pushTabs(this.parentName, this.name)
  },
  methods: {
    setActive() {
      setters.activeTab(this.parentName, this.name)
      this.$emit('change', this.name)
      if (process.client) {
        this.$el.closest('.tabs__navigation-wrapper').scrollTo({
          top: 0,
          left: this.$el.closest('.tabs__navigation-wrapper').scrollLeft + this.$el.getBoundingClientRect().left - (window.innerWidth / 2 - this.$el.getBoundingClientRect().width  / 2),
          behavior: 'smooth'
        })
      }
    }
  }
}
</script>

<style  lang="stylus">
.tabs__navigation-item
  padding 12px 24px
  border-radius 22px
  cursor pointer
  transition background-color .2s linear, color .2s linear
  white-space nowrap
  @media(hover: hover)
    &:not(.tabs__navigation-item--active)
      &:hover
        color main-color05
  &--active
    background-color #fff

  @media (max-width tablet-upper-limit)
    padding 8px 14px
</style>