<template>
  <div class="tabs__content-item" :class="{'tabs__content-item--active' : isActive}">
    <slot/>
  </div>
</template>

<script>
import { getters } from "./TabsStore"

export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    parentName: {
      type: String,
      default: ''
    },
  },
  computed: {
    isActive() {
      return getters.activeTab(this.parentName)?.length > 0 ? getters.activeTab(this.parentName) == this.name : getters.tabsArr(this.parentName)[0] == this.name
    }
  }
}
</script>

<style  lang="stylus">
.tabs__content-item
  opacity 0
  visibility hidden
  position absolute
  top 0
  bottom 0
  overflow hidden
  &--active
    transition opacity .5s linear
    opacity 1
    visibility visible
    position relative
</style>