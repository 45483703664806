<template>
  <div class="tabs">
    <div class="sp-b-2 td-sp-b-1-2 md-sp-b-1">
      <div class="tabs__navigation-wrapper">
        <div class="tabs__navigation">
          <slot name="navigation"/>
        </div>
      </div>
    </div>
    <div class="tabs__content">
      <slot name="content"/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    name: {
      type: String,
      default: ''
    }
  },
}

</script>

<style lang="stylus">
.tabs
  width 100%
  &__navigation
    display inline-flex
    align-items center
    justify-content flex-start
    background-color main-color005
    border-radius 24px
    padding 2px
    font-size 16px
    line-height 20px
    @media (max-width tablet-upper-limit)
      font-size 14px
      line-height 16px
    &-wrapper
      @media (max-width mobile-upper-limit)
        width calc(100% + 40px)
        overflow auto
        padding 0 20px
        margin 0 -20px
        -ms-overflow-style none
        scrollbar-width none
        &::-webkit-scrollbar
          display none
</style>
