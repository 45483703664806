const smoothScroll = (startPosition, endPosition) => {
    if (process.client) {
        let delta = Math.abs(endPosition - startPosition)
        let intervalTime = 25
        if (startPosition < endPosition) {
            let int = setInterval(() => {
                if (startPosition > (endPosition - delta / 100)) {
                    startPosition += Math.ceil(delta / 1000)
                } else if (startPosition > (endPosition - delta / 50)) {
                    startPosition += Math.ceil(delta / 500)
                } else if (startPosition > (endPosition - delta / 10)) {
                    startPosition += Math.ceil(delta / 100)
                } else if (startPosition > (endPosition - delta / 5)) {
                    startPosition += Math.ceil(delta / 50)
                } else if (startPosition > (endPosition - delta / 2)) {
                    startPosition += Math.ceil(delta / 10)
                } else if (startPosition >= (endPosition - delta)) {
                    startPosition += Math.ceil(delta / 5)
                }
                window.scrollTo( 0, startPosition)
                if (startPosition >= endPosition) {
                    setTimeout(() => {
                        clearInterval(int)
                    }, 11)
                }
            }, intervalTime)
            return int
        } else {
            let int = setInterval(() => {
                if (startPosition < (endPosition + delta / 100)) {
                    startPosition -= Math.ceil(delta / 1000)
                } else if (startPosition < (endPosition + delta / 50)) {
                    startPosition -= Math.ceil(delta / 500)
                } else if (startPosition < (endPosition + delta / 10)) {
                    startPosition -= Math.ceil(delta / 100)
                } else if (startPosition < (endPosition + delta / 5)) {
                    startPosition -= Math.ceil(delta / 50)
                } else if (startPosition < (endPosition + delta / 2)) {
                    startPosition -= Math.ceil(delta / 10)
                } else if (startPosition <= (endPosition + delta)) {
                    startPosition -= Math.ceil(delta / 5)
                }
                window.scrollTo( 0, startPosition)
                if (startPosition <= endPosition) {
                    setTimeout(() => {
                        clearInterval(int)
                    }, 11)
                }
            }, intervalTime)
            return int
        }
    }
}

export default smoothScroll